<template>
  <div>
    <base-card :loading="cardLoading">
      <div v-if="categoriesList == '' && !cardLoading">
        <v-card-text class="text-center">
          <div class="pa-16">
            <v-btn
              class="mx-2"
              fab
              x-large
              disabled
              depressed
              style="font-size: 399px !important"
            >
              <v-icon x-large> mdi-format-list-checkbox </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <div class="my-2">
              <span class="text-subtitle-1">No Categories Found</span>
            </div>

            <v-spacer></v-spacer>
            <v-btn dark color="primary" @click="(title = 'Add'), openDialog()">
              <v-icon>mdi-plus</v-icon>
              Add Categories
            </v-btn>
          </div>
        </v-card-text>
      </div>
      <div v-else>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <v-btn
              class="ma-2"
              small
              dark
              color="primary"
              @click="(title = 'Add'), openDialog()"
            >
              <v-icon small>mdi-plus</v-icon>
              Add Categories
            </v-btn>
            <div>
              <v-btn
                class="ma-2"
                small
                dark
                color="danger"
                v-if="selected != ''"
                @click="dialogDelete = true"
              >
                <v-icon small>mdi-delete</v-icon>
                delete
              </v-btn>
            </div>
          </div>
        </v-card-title>
        <v-card-title>
          <v-row>
            <v-col cols="12" lg="8">Categories</v-col>
            <v-col cols="12" lg="4"
              ><v-text-field
                class="mt-n5"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field
            ></v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          v-model="selected"
          :search="search"
          :headers="headers"
          :items="categoriesList"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          item-key="category_id"
          hide-default-footer
          @click:row="handleClick"
          show-select
        >
          <template v-slot:item.color="{ item }">
            <v-swatches
              v-model="item.color"
              shapes="circles"
              class="my-3"
            ></v-swatches>
          </template>
        </v-data-table>
        <div class="text-center py-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </div>
      </div>
    </base-card>

    <v-dialog v-model="dialog" width="600">
      <base-card>
        <v-card-text>
          <div class="mb-5">
            <span class="text-h6">{{ title }} Categories</span>
          </div>
          <v-text-field
            prepend-icon="mdi-alphabetical-variant"
            label="Categories Name"
            v-model="cName"
            :error-messages="cNameErrors"
            required
            @input="$v.cName.$touch()"
            @blur="$v.cName.$touch()"
          ></v-text-field>

          <v-text-field
            prepend-icon="mdi-format-list-numbered"
            v-model="sequenceNumber"
            label="Sequence Number (optional)"
            :error-messages="sequenceNumberErrors"
            @input="$v.sequenceNumber.$touch()"
            @blur="$v.sequenceNumber.$touch()"
          >
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
                </template>
                Sequence of the category (Customer View)
              </v-tooltip>
            </template>
          </v-text-field>

          <span class="text-subtitle-1">Choose a color</span>
          <v-spacer></v-spacer>
          <v-swatches
            v-model="color"
            :swatches="swatches"
            label="choose color"
            inline
          ></v-swatches>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            v-if="title == 'Edit'"
            @click="dialogDelete = true"
            text
            icon
            color="red lighten-2"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogAction()" text> save </v-btn>
        </v-card-actions>
      </base-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title class="text-subtitle-1"
          >Are you sure to delete this category? Promotion and printer tie to
          this category will be delete.</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" @click="deleteCategory" text>OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { BASEURL, BASESYNCURL } from "@/api/baseurl";
import axios from "axios";
import { validationMixin } from "vuelidate";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import { required,numeric,minValue } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    cName: { required },
    sequenceNumber:{minValue: minValue(1) , numeric}
  },
  components: { VSwatches },

  data() {
    return {
      domain: BASEURL,
      syncDomain: BASESYNCURL,
      branchList: "",
      snackbar: false,
      text: "",
      snackbarColor: "",
      timeout: 1900,
      companyID: "",
      sequenceNumber:"",
      headers: [
      { text: "Category_id", value: "category_id" },
        {
          text: "Name",
          sortable: true,
          value: "name",
        },
        { text: "Items", value: "items", sortable: true },
        { text: "Color", value: "color", sortable: false },
      ],
      categoriesList: [],
      dialog: false,
      title: "",
      cName: "",
      cPhone: "",
      cEmail: "",
      cAddress: "",
      selectCategories: "",
      cardLoading: true,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialogDelete: false,
      search: "",
      selected: [],
      color: "#E0E0E0",
      swatches: [
        "#E0E0E0",
        "#F44366",
        "#E91E63",
        "#FF9800",
        "#CDDC39",
        "#4CAF50",
        "#2196F3",
        "#9C27B0",
      ],
    };
  },

  computed: {
    cNameErrors() {
      const errors = [];
      if (!this.$v.cName.$dirty) return errors;
      !this.$v.cName.required && errors.push("Customer name is required");
      return errors;
    },
    sequenceNumberErrors() {
      const errors = [];
      if (!this.$v.sequenceNumber.$dirty) return errors;
      !this.$v.sequenceNumber.numeric && errors.push("Seats must be integer");
      !this.$v.sequenceNumber.minValue && errors.push("Must be positive and not 0");
      
      return errors;
    },

  },

  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getAllCategories();
    this.getCompanyBranch();
  },

  methods: {
    getAllCategories() {
      const params = new URLSearchParams();
      params.append("getAllCategories", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/categories/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.categoriesList = response.data.categories;
            this.cardLoading = false;
          } else {
            this.categoriesList = [];
            this.cardLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openDialog() {
      if (this.title == "Add") {
        this.$v.$reset();
        this.cName = "";
        this.sequenceNumber = "";
        this.color = "#E0E0E0";
        this.dialog = true;
      } else {
        this.$v.$reset();
        this.dialog = true;
      }
    },

    handleClick(item) {
      this.cName = item.name;
      this.color = item.color;
      this.sequenceNumber = item.sequence;
      this.selectCategories = item.category_id;
      this.title = "Edit";
      this.openDialog();
    },

    deleteCategory() {
      if (this.selected != "") {
        const array = [];
        for (let i = 0; i < this.selected.length; i++) {
          array.push(this.selected[i].category_id);
        }
        const params = new URLSearchParams();
        params.append("deleteMultiCategories", "done");
        params.append("array_categories_id", JSON.stringify(array));
        params.append("company_id", this.companyID);
        axios({
          method: "post",
          url: this.domain + "/categories/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.dialog = false;
              this.text = "Successfully delete";
              this.snackbar = true;
              this.snackbarColor = "success";
              this.selected = [];
              this.dialogDelete = false;
              this.getAllCategories();
              this.sendNotication();
            } else {
              this.text = "Please try again later";
              this.snackbar = true;
              this.snackbarColor = "error";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const params = new URLSearchParams();
        params.append("deleteCategories", "done");
        params.append("category_id", this.selectCategories);
        params.append("company_id", this.companyID);

        axios({
          method: "post",
          url: this.domain + "/categories/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.dialog = false;
              this.text = "Successfully delete";
              this.snackbar = true;
              this.snackbarColor = "success";
              this.dialogDelete = false;
              this.getAllCategories();
              this.sendNotication();
            } else {
              this.text = "Please try again later";
              this.snackbar = true;
              this.snackbarColor = "error";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("getCompanyBranch", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchList = response.data.branch;
          } else {
            console.log("no list get");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    sendNotication() {
      for (let i = 0; i < this.branchList.length; i++) {
        const params = new URLSearchParams();
        params.append("send_notification", "done");
        params.append("branch_id", this.branchList[i].branch_id);
        params.append("type", "1");

        axios({
          method: "post",
          url: this.syncDomain + "/sync/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              console.log("Send Notification successfully");
            } else {
              console.log("Send Notification fail");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    dialogAction() {
      if (this.title == "Add") {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const params = new URLSearchParams();
          params.append("addCategories", "done");
          params.append("company_id", this.companyID);
          params.append("name", this.cName);
          params.append("sequence", this.sequenceNumber);
          params.append("color", this.color);

          axios({
            method: "post",
            url: this.domain + "/categories/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.dialog = false;
                this.text = "Successfully add";
                this.snackbar = true;
                this.snackbarColor = "success";
                this.getAllCategories();
                this.sendNotication();
              } else {
                this.text = "Please try again later";
                this.snackbar = true;
                this.snackbarColor = "error";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const params = new URLSearchParams();
          params.append("editCategories", "done");
          params.append("name", this.cName);
          params.append("color", this.color);
          params.append("sequence", this.sequenceNumber);
          params.append("category_id", this.selectCategories);
          params.append("company_id", this.companyID);

          axios({
            method: "post",
            url: this.domain + "/categories/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.dialog = false;
                this.text = "Successfully edit";
                this.snackbar = true;
                this.snackbarColor = "success";
                this.getAllCategories();
                this.sendNotication();
              } else {
                this.text = "Please try again later";
                this.snackbar = true;
                this.snackbarColor = "error";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
  },
};
</script>

<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>