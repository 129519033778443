<template>
  <v-row>
    <v-col cols="12">
        <categories-card> </categories-card> 
    </v-col>
  </v-row>
</template>
<script>
import CategoriesCard from "@/components/product/CategoriesCard";

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Product Categories",
  },

  components: {
    CategoriesCard
  },

  data() {
    return {
    };
  },
};
</script>